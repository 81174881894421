import ExcelJS from 'exceljs';
import {
    format,
    parse,
    lastDayOfMonth,
    isSaturday,
    isSunday,
    isWithinInterval,
    subHours,
    addHours,
} from 'date-fns';
import { getDatesInRange, applyFiltersToCustomerData } from './data';

const getValue = (field, value, data, customerId, service) => {
    if (field.isDate) {
        return value ? format(new Date(value), 'yyyy-MM-dd') : '';
    }
    if (field.isBit) {
        return value?.data?.[0] == 1 ? 'Yes' : 'No';
    }
    if (field.custom) {
        if (field.name.indexOf('date-') > -1) {
            // If attendance/usual dates

            // {
            //     "customer_id": 56,
            //     "id": 3,
            //     "name": "Emergency Food Store",
            //     "date": "2023-11-10T00:00:00.000Z",
            //     "datestatus_id": 7,
            //     "datestatus_name": "Paid"
            // }

            return data?.attendanceData?.find(
                (d) =>
                    d.name == service &&
                    format(d.date, 'dd/MM/yyyy') == field.name.split('-')[1] &&
                    d.customer_id == customerId
            )?.datestatus_name;
        } else {
            switch (field.name) {
                case 'gender':
                    return value === 'm' ? 'Male' : 'Female';
                case 'country_id':
                    return value && value > 0
                        ? data?.lookupData?.country?.data?.find((rec) => rec.id == value).name
                        : '';
                case 'marital_id':
                    return value && value > 0
                        ? data?.lookupData?.marital?.data?.find((rec) => rec.id == value).name
                        : '';
                case 'service':
                    return (
                        data?.serviceData
                            ?.filter((rec) => rec.customer_id == customerId)
                            ?.map((rec) => rec.name)
                            ?.join(', ') ?? ''
                    );
                case 'adults_household_age':
                    return (
                        data?.customerAdultAgeData
                            ?.filter((rec) => rec.customer_id == customerId)
                            ?.map((rec) => `${rec.name} (${rec.val})`)
                            ?.join(', ') ?? ''
                    );
                case 'children_household_age':
                    return (
                        data?.customerChildAgeData
                            ?.filter((rec) => rec.customer_id == customerId)
                            ?.map((rec) => `${rec.name} (${rec.val})`)
                            ?.join(', ') ?? ''
                    );
                case 'housing_id':
                    return value && value > 0
                        ? data?.lookupData?.housing?.data?.find((rec) => rec.id == value).name
                        : '';
                case 'employment_id':
                    return value && value > 0
                        ? data?.lookupData?.employment?.data?.find((rec) => rec.id == value).name
                        : '';
                case 'funding_id':
                    return value && value > 0
                        ? data?.lookupData?.funding?.data?.find((rec) => rec.id == value).name
                        : '';
                case 'health':
                    return (
                        data?.customerHealthData
                            ?.filter((rec) => rec.customer_id == customerId)
                            ?.map((rec) => rec.name)
                            ?.join(', ') ?? ''
                    );
                case 'energy_tried':
                    return (
                        data?.customerEnergyTryData
                            ?.filter((rec) => rec.customer_id == customerId)
                            ?.map((rec) => rec.name)
                            ?.join(', ') ?? ''
                    );
                case 'bill':
                    return (
                        data?.customerBillData
                            ?.filter((rec) => rec.customer_id == customerId)
                            ?.map((rec) => rec.name)
                            ?.join(', ') ?? ''
                    );
            }
        }
    }

    return value;
};

const applyFiltersToCustomers = (customers, data, service, activeInactive, filters) => {
    let custom = [...customers];

    // Services
    const customerIds = data?.serviceData
        ?.filter((rec) => rec.name == service)
        .map((rec) => rec.customer_id);
    custom =
        customerIds?.length > 0 ? custom.filter((rec) => customerIds.includes(rec.id)) : custom;

    // Active/Inactive
    if (activeInactive !== 'Both') {
        if (activeInactive == 'Active') {
            custom = custom.filter((rec) => rec.enabled?.data?.[0] == 1);
        } else {
            custom = custom.filter((rec) => !(rec.enabled?.data?.[0] == 1));
        }
    }

    // Additional filters
    custom = applyFiltersToCustomerData(custom, service, filters, data);

    return custom;
};

const datesInRangeList = (dates) => {
    const dateFrom = parse(
        `${dates.dateFrom.day}/${dates.dateFrom.month}/${dates.dateFrom.year}`,
        'd/MMMM/yyyy',
        new Date()
    );
    let dateTo;
    dateTo = parse(
        `${dates.dateTo.day}/${dates.dateTo.month}/${dates.dateTo.year}`,
        'd/MMMM/yyyy',
        new Date()
    );
    if (dateTo == 'Invalid Date') {
        dateTo = lastDayOfMonth(
            parse(`1/${dates.dateTo.month}/${dates.dateTo.year}`, 'd/MMMM/yyyy', new Date())
        );
    }

    return getDatesInRange(dateFrom, dateTo);
};

const addDatesTitles = (dates, these) => {
    const startCount = these.length;

    let those = [...these];

    const newDates = datesInRangeList(dates);

    const isDifferentMonth =
        newDates?.[0]?.getMonth() !== newDates?.[newDates.length - 1]?.getMonth();
    const isDifferentYear =
        newDates?.[0]?.getFullYear() !== newDates?.[newDates.length - 1]?.getFullYear();

    // Find weekends
    const weekendIndexes = newDates
        .map((d, i) => {
            return isSaturday(d) || isSunday(d) ? i + startCount - 1 : null;
        })
        .filter(Number);

    those = those.concat(
        newDates.map((d, idx) => {
            return {
                name: 'date-' + format(d, 'dd/MM/yyyy'),
                label: format(
                    d,
                    'eee do' + (isDifferentMonth ? ' MMM' : '') + (isDifferentYear ? ' yyyy' : '')
                ),
                width: weekendIndexes.includes(idx + startCount - 1) ? 6 : 15,
                custom: true,
            };
        })
    );

    return { those, weekendIndexes };
};

const findUsualDateIndexes = (usualDates, dates, customersFiltered, service, xStartCount) => {
    // {
    //     "customer_id": 272,
    //     "service_id": 1,
    //     "name": "Adult Day Care",
    //     "date": "2020-01-06T00:00:00.000Z",
    //     "funding_id": 1,
    //     "funding": "Private"
    // }

    console.log('xStartCount', xStartCount);

    const indexes = [];

    const newDates = datesInRangeList(dates);

    // Only Usual Dates for active customers
    const relevantUsualDates = usualDates
        .filter((d) => customersFiltered.map((c) => c.id).includes(d.customer_id))
        .filter(
            (d) =>
                d.name == service &&
                isWithinInterval(d.date, {
                    start: subHours(newDates[0], 1),
                    end: addHours(newDates[newDates.length - 1], 1),
                })
        );

    customersFiltered.forEach((c, idx) => {
        newDates.forEach((dateRange, nidx) => {
            const found = relevantUsualDates?.find((d) => {
                return (
                    d.customer_id == c.id &&
                    d.name == service &&
                    format(d.date, 'yyyy-MM-dd') == format(dateRange, 'yyyy-MM-dd')
                );
            });
            if (found) {
                indexes.push({
                    row: idx + 3,
                    cell: nidx + (xStartCount - 1),
                    funding: found.funding,
                });
            }
        });
    });

    // console.log('indexes', indexes);

    return indexes;
};

const createXslt = async (
    fields,
    selectedFields,
    dates,
    service,
    activeInactive,
    data,
    filters,
    debug
) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Customer DB';
    workbook.created = new Date();

    const worksheet = workbook.addWorksheet('Extracted');

    if (fields?.length > 0 && selectedFields?.length > 0) {
        let these = fields.filter((field) => selectedFields?.includes(field.label));
        let weekendIdxs = [];
        let xStartCount = these?.length ?? 0;
        let usualDatesIndexes = [];

        //  if field.name == 'Service(s)' || field.name == 'Attendance', build grid by dates
        const buildDateGrids =
            these.find((s) => s.name === 'service') != null &&
            these.find((s) => s.name === 'attendance') != null;
        if (buildDateGrids) {
            const { those, weekendIndexes } = addDatesTitles(dates, these);
            these = those;
            weekendIdxs = weekendIndexes;

            these = these.filter((x) => !(x.name === 'service' || x.name === 'attendance'));
        }

        worksheet.columns = these.map((field) => {
            return {
                header: field.label,
                key: field.name,
                width: field.width ? field.width : 15,
                style: { font: { name: 'Arial', size: 10 } },
            };
        });

        worksheet.addRow();

        const records = [];
        const customersFiltered = applyFiltersToCustomers(
            data.customerData,
            data,
            service,
            activeInactive,
            filters
        );
        customersFiltered.forEach((customer) => {
            const rowData = {};
            these.map((field) => {
                rowData[field.name] = getValue(
                    field,
                    customer[field.name],
                    data,
                    customer.id,
                    service
                );
            });
            records.push(rowData);
            worksheet.addRow(rowData);
        });

        if (buildDateGrids) {
            // Fix gmt/bst hour fix
            const usualDates = data.usualDates?.map((u) => {
                return { ...u, date: addHours(u.date, 1) };
            });
            usualDatesIndexes = findUsualDateIndexes(
                usualDates,
                dates,
                customersFiltered,
                service,
                xStartCount
            );
        }

        worksheet.getRow(1).font = { name: 'Arial', size: 10, bold: true };

        // Weekend blocking
        const recordsCount = records.length;
        for (let rx = 0; rx <= recordsCount + 1; rx++) {
            weekendIdxs.forEach((idx) => {
                worksheet.getRow(rx + 1).getCell(idx).fill = {
                    type: 'pattern',
                    pattern: 'lightDown',
                    bgColor: {
                        argb: '00FFCC99',
                    },
                };
            });
        }

        // Usual attendance blocking
        usualDatesIndexes.map((d) => {
            worksheet.getRow(d.row).getCell(d.cell).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: d.funding == 'Private' ? 'FFd5ecf7' : 'FFf7d5d5',
                },
                bgColor: {
                    argb: 'FF000000',
                },
            };
        });

        if (debug) {
            console.log(JSON.stringify(these, null, 4));
            console.log(JSON.stringify(records, null, 4));
            return;
        } else {
            return await workbook.xlsx.writeBuffer();
        }
    }

    return;
};
export { createXslt };
