import classNames from 'classnames';
import { Button, Text } from '@radix-ui/themes';
import { MultiGrid } from 'react-virtualized';
import { AutoSizer } from 'react-virtualized';
import * as Tooltip from '@radix-ui/react-tooltip';
import PopupMenu from './PopupMenu';
import { ThickArrowLeftIcon, ThickArrowRightIcon } from '@radix-ui/react-icons';
import { format, parse } from 'date-fns';
import { stringDateBetweenDays } from '../helpers/data';

function AttendanceMonth({
    onDateClick,
    onDateChange,
    thisMonths,
    customers,
    attendanceDates,
    customerDates,
    dateStatusData,
}) {
    const dates = (customer) => {
        return thisMonths?.map((date) => {
            const hasCustomerDate = customerDates
                ?.filter((customerDate) => customerDate.customer_id === customer.id)
                .map((c) => c.date)
                .includes(format(date, 'do MMMM yyyy'));

            let funding;
            if (hasCustomerDate) {
                funding = customerDates?.find(
                    (customerDate) =>
                        customerDate.customer_id === customer.id &&
                        customerDate.date == format(date, 'do MMMM yyyy')
                )?.funding_name;
            }

            const attendanceDate = attendanceDates?.find(
                (customerDate) =>
                    customerDate.customer_id === customer.id &&
                    customerDate.date == format(date, 'do MMMM yyyy')
            );

            return attendanceDate
                ? { ...attendanceDate, hasCustomerDate, funding }
                : {
                      customer_id: customer.id,
                      date: format(date, 'do MMMM yyyy'),
                      hasCustomerDate,
                      funding,
                  };
        });
    };

    const dateHeadings = thisMonths?.map((date) => format(date, 'EEE do'));

    // Data
    const list = customers?.map((customer) => {
        const values = dates(customer);
        return [
            `${customer.last_name + ', ' + customer.first_name}|${
                customer.dob?.length > 0 ? format(new Date(customer.dob), 'do MMMM yyyy') : ''
            }|${customer.gender === 'm' ? 'Male' : 'Female'}`,
        ].concat(values);
    });
    list.unshift(['Customer name', ...dateHeadings]);

    function cellRenderer({ columnIndex, key, rowIndex, style }) {
        if (rowIndex === 0) {
            return (
                <div key={key} style={style} className="MonthDateCell">
                    <Text>{list[rowIndex][columnIndex]}</Text>
                </div>
            );
        } else if (columnIndex === 0 && rowIndex > 0) {
            const parts = list[rowIndex][columnIndex].split('|');
            return (
                <div key={key} style={style} className="MonthDateCell">
                    <Text className="NameLabel">{parts[0]}</Text>
                    <br />
                    <Text color="gray" size="1">
                        {parts[1]}
                        {parts?.[1]?.length > 0 && parts?.[2]?.length > 0 ? ' / ' : ''}
                        {parts[2]}
                    </Text>
                </div>
            );
        } else {
            const cell = list[rowIndex][columnIndex];

            const popupClasses = classNames({
                DateCell: true,
                DateCellLast: false,
                DateCellOn: cell?.hasCustomerDate,
                DateCellOnCouncil: cell?.funding === 'Council' ? true : false,
            });

            return (
                <PopupMenu
                    isMonth={true}
                    onClick={(statusId, statusName) =>
                        onDateClick(
                            cell.customer_id,
                            parse(cell.date, 'do MMMM yyyy', new Date()),
                            statusId,
                            statusName
                        )
                    }
                    dateStatusData={{
                        data: dateStatusData?.data.concat([{ id: 99, name: 'Clear', ord: 99 }]),
                    }}
                    attendanceDate={cell}
                    classes={popupClasses}
                    key={`customer-attendance-status-${key}`}
                    style={style}
                    funding={cell?.funding}
                />
            );
        }
    }

    function columnWidth({ index }) {
        return index > 0 ? 120 : 300;
    }

    return (
        <>
            <div className="GridHeader">
                <Tooltip.Provider>
                    <Tooltip.Root>
                        <Tooltip.Trigger>
                            <Button
                                variant="soft"
                                className="LeftRightArrow"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onDateChange('left');
                                }}
                            >
                                <ThickArrowLeftIcon width="24" height="24" />
                            </Button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                View previous month
                                <Tooltip.Arrow className="TooltipArrow" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
                <Text className="DateRange">{stringDateBetweenDays(thisMonths)}</Text>
                <Tooltip.Provider>
                    <Tooltip.Root>
                        <Tooltip.Trigger>
                            <Button
                                variant="soft"
                                className="LeftRightArrow"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onDateChange('right');
                                }}
                            >
                                <ThickArrowRightIcon width="24" height="24" />
                            </Button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                View next month
                                <Tooltip.Arrow className="TooltipArrow" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
            </div>
            <AutoSizer>
                {({ height, width }) => (
                    <MultiGrid
                        className="month-view"
                        cellRenderer={cellRenderer}
                        columnCount={list[0].length}
                        rowCount={list.length}
                        columnWidth={columnWidth}
                        classNameTopLeftGrid="TopLeft"
                        classNameTopRightGrid="TopRight"
                        classNameBottomLeftGrid="FirstGrid"
                        classNameBottomRightGrid="LastGrid"
                        rowHeight={65}
                        width={width}
                        height={height}
                        fixedRowCount={1}
                        fixedColumnCount={1}
                        enableFixedColumnScroll={true}
                        enableFixedRowScroll={true}
                    />
                )}
            </AutoSizer>
        </>
    );
}

export default AttendanceMonth;
